import { zGetConversionsListTrpcInput } from '@chatai/admin-backend/src/router/conversions/getConversionsList/input'
import { canViewConversions } from '@chatai/admin-backend/src/utils/can'
import { omit } from '@chatai/shared/src/omit'
import { format } from 'date-fns'
import InfiniteScroll from 'react-infinite-scroller'
import { Alert } from '../../../components/Alert'
import { FormItems } from '../../../components/FormItems'
import { Input } from '../../../components/Input'
import { layoutContentElRef } from '../../../components/Layout'
import { Loader } from '../../../components/Loader'
import { Segment } from '../../../components/Segment'
import { Textarea } from '../../../components/Textarea'
import { useForm } from '../../../lib/form'
import { withPageWrapper } from '../../../lib/pageWrapper'
import { trpc } from '../../../lib/trpc'
import css from './index.module.scss'

const validationSchema = zGetConversionsListTrpcInput.pick({
  dateFrom: true,
  dateTo: true,
  search: true,
  groupBy: true,
})
export const ConversionsListPage = withPageWrapper({
  title: 'Conversions List',
  checkAccess: ({ ctx }) => canViewConversions(ctx.me),
})(() => {
  const { formik } = useForm({
    initialValues: { dateFrom: '', dateTo: '', search: '' },
    validationSchema,
  })
  const filter = (() => {
    const maybeFilter = {
      search: formik.values.search,
      dateFrom: formik.values.dateFrom,
      dateTo: formik.values.dateTo,
    }
    const parseResult = validationSchema.safeParse(maybeFilter)
    return (parseResult.success ? maybeFilter : {}) as {
      search: string
      dateFrom: string
      dateTo: string
    }
  })()

  const qr = trpc.getConversionsList.useInfiniteQuery(
    {
      ...filter,
    },
    {
      getNextPageParam: (lastPage) => {
        return lastPage.nextCursor
      },
    }
  )

  const conversionEvents = qr.data?.pages.flatMap((page) => page.conversionEvents) || []
  const omitKeys = ['serialNumber', 'id'] as any

  return (
    <Segment title="Conversions List" helpId="conversions-list">
      <div className={css.filter}>
        <FormItems>
          <Textarea
            maxWidth={'100%'}
            label="Search Params"
            name="search"
            formik={formik}
            initialHeight={60}
            hint={
              <>
                mediaSource=FacebookAds
                <br />
                campaignId=~Reels
              </>
            }
          />
          <Input maxWidth={'100%'} label="Date From" name="dateFrom" formik={formik} />
          <Input maxWidth={'100%'} label="Date To" name="dateTo" formik={formik} />
        </FormItems>
      </div>
      {qr.error ? (
        <Alert color="red">{qr.error.message}</Alert>
      ) : !qr.data ? (
        <Loader type="section" />
      ) : !conversionEvents.length ? (
        <Alert color="brown">Nothing found by search</Alert>
      ) : (
        <table className={css.table}>
          <thead>
            <tr>
              {Object.keys(omit(conversionEvents[0], omitKeys)).map((cek) => (
                <th key={cek}>{cek}</th>
              ))}
            </tr>
          </thead>
          <InfiniteScroll
            threshold={250}
            loadMore={() => {
              if (!qr.isFetchingNextPage && qr.hasNextPage) {
                void qr.fetchNextPage()
              }
            }}
            className={css.tbody}
            hasMore={qr.hasNextPage}
            loader={
              <tr key={'loader'}>
                <td className={css.more} key="loader">
                  <Loader type="section" />
                </td>
              </tr>
            }
            element={'tbody'}
            getScrollParent={() => layoutContentElRef.current}
            useWindow={(layoutContentElRef.current && getComputedStyle(layoutContentElRef.current).overflow) !== 'auto'}
          >
            {conversionEvents.map((ce) => (
              <tr key={ce.serialNumber}>
                {Object.values(omit(ce, omitKeys)).map((val: any, i) => (
                  <td key={i}>
                    {val?.toISOString
                      ? format(val, 'yyyy-MM-dd HH:mm:ss')
                      : !val
                      ? '—'
                      : typeof val === 'number'
                      ? val.toFixed(2)
                      : val?.toString?.() || '—'}
                  </td>
                ))}
              </tr>
            ))}
          </InfiniteScroll>
        </table>
      )}
    </Segment>
  )
})
