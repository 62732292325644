import { zGetUnsubscriptionsTrpcInput } from '@chatai/admin-backend/src/router/unsubscriptions/getUnsubscriptions/input'
import { canViewUnsubscriptions } from '@chatai/admin-backend/src/utils/can'
import InfiniteScroll from 'react-infinite-scroller'
import { Link } from 'react-router-dom'
import { Alert } from '../../../components/Alert'
import { FormItems } from '../../../components/FormItems'
import { Input } from '../../../components/Input'
import { layoutContentElRef } from '../../../components/Layout'
import { Loader } from '../../../components/Loader'
import { Segment } from '../../../components/Segment'
import { useForm } from '../../../lib/form'
import { withPageWrapper } from '../../../lib/pageWrapper'
import { getUnsubscriptionRoute, getUserRoute } from '../../../lib/routes'
import { trpc } from '../../../lib/trpc'
import css from './index.module.scss'

const validationSchema = zGetUnsubscriptionsTrpcInput.pick({ dateFrom: true, dateTo: true })
export const UnsubscriptionsPage = withPageWrapper({
  title: 'Unsubscriptions',
  checkAccess: ({ ctx }) => canViewUnsubscriptions(ctx.me),
})(() => {
  const { formik } = useForm({
    initialValues: { dateFrom: '', dateTo: '' },
    validationSchema,
  })
  const filter = (() => {
    const maybeFilter = {
      dateFrom: formik.values.dateFrom,
      dateTo: formik.values.dateTo,
    }
    const parseResult = validationSchema.safeParse(maybeFilter)
    return parseResult.success ? maybeFilter : {}
  })()

  const { data, error, isLoading, isError, hasNextPage, fetchNextPage, isFetchingNextPage, isRefetching } =
    trpc.getUnsubscriptions.useInfiniteQuery(
      {
        ...filter,
      },
      {
        getNextPageParam: (lastPage) => {
          return lastPage.nextCursor
        },
      }
    )

  return (
    <Segment title="Unsubscriptions" helpId="unsubscriptions">
      <div className={css.filter}>
        <FormItems>
          <Input maxWidth={'100%'} label="Date From" name="dateFrom" formik={formik} />
          <Input maxWidth={'100%'} label="Date To" name="dateTo" formik={formik} />
        </FormItems>
      </div>
      {isLoading || isRefetching ? (
        <Loader type="section" />
      ) : isError ? (
        <Alert color="red">{error.message}</Alert>
      ) : !data.pages[0].unsubscriptions.length ? (
        <Alert color="brown">Nothing found by search</Alert>
      ) : (
        <div className={css.unsubscriptions}>
          <table className={css.table}>
            <thead>
              <tr>
                <th>Unsubscription SN</th>
                <th>User SN</th>
                <th>User Created At</th>
                <th>Unsubscribed At</th>
                <th>Duration, h</th>
                <th>Chats Count</th>
                <th>Messages Count</th>
                <th>Free Coins Count</th>
              </tr>
            </thead>
            <InfiniteScroll
              threshold={250}
              loadMore={() => {
                if (!isFetchingNextPage && hasNextPage) {
                  void fetchNextPage()
                }
              }}
              className={css.tbody}
              hasMore={hasNextPage}
              loader={
                <tr>
                  <td className={css.more} key="loader">
                    <Loader type="section" />
                  </td>
                </tr>
              }
              getScrollParent={() => layoutContentElRef.current}
              useWindow={
                (layoutContentElRef.current && getComputedStyle(layoutContentElRef.current).overflow) !== 'auto'
              }
            >
              {data.pages
                .flatMap((page) => page.unsubscriptions)
                .map((unsubscription) => (
                  <tr className={css.unsubscription} key={unsubscription.serialNumber}>
                    <td>
                      <Link
                        className={css.unsubscriptionLink}
                        to={getUnsubscriptionRoute({
                          unsubscriptionSerialNumber: unsubscription.serialNumber.toString(),
                        })}
                      >
                        {unsubscription.serialNumber}
                      </Link>
                    </td>
                    <td>
                      <Link
                        className={css.unsubscriptionLink}
                        to={getUserRoute({
                          userSerialNumber: unsubscription.userSerialNumber.toString(),
                        })}
                      >
                        {unsubscription.userSerialNumber}
                      </Link>
                    </td>
                    <td>{unsubscription.userCreatedAt.toISOString()}</td>
                    <td>{unsubscription.createdAt.toISOString()}</td>
                    <td>{unsubscription.duration}</td>
                    <td>{unsubscription.chatsCount}</td>
                    <td>{unsubscription.messagesCount}</td>
                    <td>{unsubscription.freeCoinsCountPerMonth}</td>
                  </tr>
                ))}
            </InfiniteScroll>
          </table>
        </div>
      )}
    </Segment>
  )
})
