import { canManageUsers } from '@chatai/admin-backend/src/utils/can'
import { Alert } from '../../../components/Alert'
import { Button } from '../../../components/Button'
import { FormItems } from '../../../components/FormItems'
import { Segment } from '../../../components/Segment'
import { env } from '../../../lib/env'
import { useForm } from '../../../lib/form'
import { withPageWrapper } from '../../../lib/pageWrapper'
import { trpc } from '../../../lib/trpc'

export const ClearUsersPage = withPageWrapper({
  authorizedOnly: true,
  checkAccess: ({ ctx }) => canManageUsers(ctx.me),
  title: 'Users Cleaning',
})(() => {
  const destroyAllUsers = trpc.destroyAllUsers.useMutation()
  const { formik, alertProps, buttonProps } = useForm({
    onSubmit: async () => {
      await destroyAllUsers.mutateAsync()
    },
    resetOnSuccess: false,
    enableReinitialize: true,
    successMessage: 'All users have been deleted',
  })

  if (env.HOST_ENV === 'production') {
    return (
      <Segment title="Users Cleaning" helpId="users-cleaning">
        <Alert color="red">You can't delete users in production</Alert>
      </Segment>
    )
  }

  return (
    <Segment title="Users Cleaning" helpId="users-cleaning">
      <form onSubmit={formik.handleSubmit}>
        <FormItems>
          <Alert {...alertProps} />
          <Button {...buttonProps}>Delete All Users</Button>
        </FormItems>
      </form>
    </Segment>
  )
})
