import { canClearAdapty } from '@chatai/admin-backend/src/utils/can'
import { useState } from 'react'
import { Alert } from '../../../components/Alert'
import { Button } from '../../../components/Button'
import { FormItems } from '../../../components/FormItems'
import { Segment } from '../../../components/Segment'
import { env } from '../../../lib/env'
import { useForm } from '../../../lib/form'
import { withPageWrapper } from '../../../lib/pageWrapper'
import { trpc } from '../../../lib/trpc'
import css from './index.module.scss'

export const ClearAdaptyPage = withPageWrapper({
  authorizedOnly: true,
  checkAccess: ({ ctx }) => canClearAdapty(ctx.me),
  title: 'Adapty Cleaning',
})(() => {
  const [result, setResult] = useState<Record<string, any> | null>(null)
  const clearAdapty = trpc.clearAdapty.useMutation()
  const { formik, alertProps, buttonProps } = useForm({
    onSubmit: async () => {
      const res = await clearAdapty.mutateAsync()
      setResult(res)
    },
    resetOnSuccess: false,
    enableReinitialize: true,
    successMessage: 'Adapty cleared',
  })

  if (env.HOST_ENV === 'production') {
    return (
      <Segment title="Adapty Cleaning" helpId="adapty-cleaning">
        <Alert color="red">You can't clear Adapty in production</Alert>
      </Segment>
    )
  }

  return (
    <Segment title="Adapty Cleaning" helpId="adapty-cleaning">
      <form onSubmit={formik.handleSubmit}>
        <FormItems>
          <Alert {...alertProps} />
          {result && <pre className={css.result}>{JSON.stringify(result, null, 2)}</pre>}
          <Button {...buttonProps}>Clear Adapty</Button>
        </FormItems>
      </form>
    </Segment>
  )
})
