import { zGetRetentionTrpcInput } from '@chatai/admin-backend/src/router/conversions/getRetention/input'
import { canViewConversions } from '@chatai/admin-backend/src/utils/can'
import { format, subDays } from 'date-fns'
import { saveAs } from 'file-saver'
import range from 'lodash/range'
import trim from 'lodash/trim'
import React, { useRef } from 'react'
import { Alert } from '../../../components/Alert'
import { Button } from '../../../components/Button/index.tsx'
import { FormItems } from '../../../components/FormItems'
import { Input } from '../../../components/Input'
import { Loader } from '../../../components/Loader'
import { Segment } from '../../../components/Segment'
import { Textarea } from '../../../components/Textarea/index.tsx'
import { useForm } from '../../../lib/form'
import { withPageWrapper } from '../../../lib/pageWrapper'
import { trpc } from '../../../lib/trpc'
import css from './index.module.scss'

const validationSchema = zGetRetentionTrpcInput.pick({
  dateFrom: true,
  dateTo: true,
  search: true,
  groupBy: true,
  purchaseX: true,
  purchaseY: true,
})
export const RetentionPage = withPageWrapper({
  title: 'Retention',
  checkAccess: ({ ctx }) => canViewConversions(ctx.me),
})(() => {
  const getRetentionCsv = trpc.getRetentionCsv.useMutation()
  const nowRef = useRef(new Date())
  const searchForm = useForm({
    initialValues: {
      dateFrom: format(subDays(nowRef.current, 7), 'dd.MM.yyyy'),
      dateTo: format(nowRef.current, 'dd.MM.yyyy'),
      search: '',
      groupBy: '',
      purchaseX: 1,
      purchaseY: 5,
    },
    validationSchema,
  })
  const csvForm = useForm({
    onSubmit: async () => {
      const { base64 } = await getRetentionCsv.mutateAsync({
        dateFrom: searchForm.formik.values.dateFrom?.toString(),
        dateTo: searchForm.formik.values.dateTo?.toString(),
        search: searchForm.formik.values.search,
        groupBy: searchForm.formik.values.groupBy,
        purchaseX: searchForm.formik.values.purchaseX,
        purchaseY: searchForm.formik.values.purchaseY,
      })
      const byteCharacters = atob(base64)
      const byteNumbers = new Array(byteCharacters.length)
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i)
      }
      const byteArray = new Uint8Array(byteNumbers)
      const blob = new Blob([byteArray], { type: 'text/csv' })
      saveAs(blob, 'retention_stats.csv')
    },
  })
  const filter = (() => {
    const maybeFilter = {
      search: searchForm.formik.values.search,
      groupBy: searchForm.formik.values.groupBy,
      dateFrom: searchForm.formik.values.dateFrom,
      dateTo: searchForm.formik.values.dateTo,
      purchaseX: +searchForm.formik.values.purchaseX,
      purchaseY: +searchForm.formik.values.purchaseY,
    }
    const parseResult = validationSchema.safeParse(maybeFilter)
    return (
      parseResult.success
        ? maybeFilter
        : {
            dateFrom: format(subDays(nowRef.current, 7), 'dd.MM.yyyy'),
            dateTo: format(nowRef.current, 'dd.MM.yyyy'),
            search: '',
            groupBy: '',
            purchaseX: 1,
            purchaseY: 5,
          }
    ) as {
      groupBy: string
      search: string
      dateFrom: string
      dateTo: string
      purchaseX: number
      purchaseY: number
    }
  })()

  const qr = trpc.getRetention.useQuery({
    ...filter,
  })
  const groupByFields = ['type', ...(filter.groupBy?.split(',').map(trim).filter(Boolean) || [])]
  const purchasesNumbers = range(filter.purchaseX, filter.purchaseY + 1)

  return (
    <Segment title="Retention" helpId="retention">
      <div className={css.filter}>
        <FormItems>
          <Textarea
            maxWidth={'100%'}
            label="Search Params"
            name="search"
            formik={searchForm.formik}
            initialHeight={60}
            hint={
              <>
                mediaSource=FacebookAds
                <br />
                campaignId=~Reels
              </>
            }
          />
          <Input
            maxWidth={'100%'}
            label="Group By"
            name="groupBy"
            formik={searchForm.formik}
            hint="day, mediaSource, channel, campaign, campaignId, adset, adsetId, ad, adId, siteId, keywords, countryCode"
          />
          <Input maxWidth={'100%'} label="Date From" name="dateFrom" formik={searchForm.formik} />
          <Input maxWidth={'100%'} label="Date To" name="dateTo" formik={searchForm.formik} />
          <Input maxWidth={'100%'} label="Purchase X" name="purchaseX" formik={searchForm.formik} />
          <Input maxWidth={'100%'} label="Purchase Y" name="purchaseY" formik={searchForm.formik} />
          {!qr.error && (
            <Button
              type="button"
              onClick={() => {
                csvForm.formik.handleSubmit()
              }}
            >
              Download CSV
            </Button>
          )}
        </FormItems>
      </div>
      {qr.error ? (
        <Alert color="red">{qr.error.message}</Alert>
      ) : !qr.data ? (
        <Loader type="section" />
      ) : (
        <table className={css.table}>
          <thead>
            <tr>
              {/* <th>Group By: {filter.groupBy || 'none'}</th> */}
              {groupByFields.map((field, i) => (
                <th key={i}>Group: {field || '—'}</th>
              ))}
              <th colSpan={2}>Install</th>
              <th colSpan={2}>First&nbsp;Open</th>
              <th colSpan={2}>Trial</th>
              {purchasesNumbers.map((num, i) => (
                <th key={i} colSpan={2}>
                  Purchase&nbsp;{num}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {qr.data.map((item, j) => {
              return (
                <tr key={j}>
                  {/* <td>{item.groupByValue}</td> */}
                  {item.groupByValues.map((v, i) => (
                    <td key={i}>{v || '—'}</td>
                  ))}
                  <td className={css.rightBorderDimmed}>{item.stats.install}</td>
                  <td>{item.stats.installRatio.toFixed(2) + '%'}</td>
                  <td className={css.rightBorderDimmed}>{item.stats.firstOpen}</td>
                  <td>{item.stats.firstOpenRatio.toFixed(2) + '%'}</td>
                  <td className={css.rightBorderDimmed}>{item.stats.startTrialWeek}</td>
                  <td>{item.stats.startTrialWeekRatio.toFixed(2) + '%'}</td>
                  {item.purchases.map((purchase, i) => {
                    return (
                      <React.Fragment key={i}>
                        <td className={css.rightBorderDimmed}>{purchase.count}</td>
                        <td>{purchase.ratio.toFixed(2) + '%'}</td>
                      </React.Fragment>
                    )
                  })}
                </tr>
              )
            })}
          </tbody>
        </table>
      )}
    </Segment>
  )
})
